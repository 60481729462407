import React from 'react';
import './Grid.css';
import GridComp from './GridComp/GridComp';
import { Rendel, Rendel1, Rendel2, Rendel3, Rendel4, Rendel5 } from './GridComp/pics/imageIndex';
import { Street, Street1, Street2 ,Street3, Street4 } from './GridComp/pics/imageIndex';
import { Duck, Duck1, Duck2, Duck3, Duck4 } from './GridComp/pics/imageIndex';
import { Work, Work1, Work2, Work3, Work4 } from './GridComp/pics/imageIndex';
import { Nono, Nono1, Nono2, Nono3, Nono4 } from './GridComp/pics/imageIndex';
import { Box, Box1, Box2, Box3, Box4, Box5 } from './GridComp/pics/imageIndex';
import { Calc, Calc1, Calc2, Calc3, Calc4 } from './GridComp/pics/imageIndex';
import { Poke, Poke1, Poke2, Poke3, Poke4 } from './GridComp/pics/imageIndex';
import { Roscript, Roscript1, Roscript2, Roscript3, Roscript4 } from './GridComp/pics/imageIndex';
import { CharSheet, CharSheet1, CharSheet2, CharSheet3, CharSheet4} from './GridComp/pics/imageIndex'; 
import { PiBoard, PiBoard1, PiBoard2, PiBoard3, PiBoard4 } from './GridComp/pics/imageIndex';
import { Char, Char1, Char2, Char3, Char4 } from './GridComp/pics/imageIndex';


export default function Grid(){
    const charImages = [Char, Char1, Char2, Char3, Char4]
    const piBoardImages = [PiBoard, PiBoard1, PiBoard2, PiBoard3, PiBoard4];
    const charSheetImages = [CharSheet, CharSheet1, CharSheet2, CharSheet3, CharSheet4];
    const roscriptImages = [Roscript, Roscript1, Roscript2, Roscript3, Roscript4];
    const pokeImages = [Poke, Poke1, Poke2, Poke3, Poke4];
    const calcImages = [Calc, Calc1, Calc2, Calc3, Calc4];
    const rendelImages = [Rendel, Rendel2, Rendel3, Rendel4, Rendel5, Rendel1];
    const streetImages = [Street, Street1, Street2, Street3, Street4];
    const duckImages = [Duck, Duck1, Duck2, Duck3, Duck4];
    const workImages = [Work, Work1, Work2, Work3, Work4];
    const nonoImages = [Nono, Nono1, Nono2, Nono3, Nono4];
    const boxImages = [Box, Box1, Box2, Box3, Box4, Box5];
    
    const charText = ["Character Sheet 2.0", "Node.js, Websockets, SQL", "Welcome to the new web-socket based CharSheet! \n\nWhen I first made the program last year, I knew that my solution wasn't enough. Numerous desync issues plagued my API-based solution, and support for desktop was limited. Inspired by Google Docs and video compression programs, I restructured my project around live-editing, transmitting relevant deltas through websockets. \n\nLinked below is the new CharSheet. It supports concurrent live-editing from multiple devices, so have fun messing around!", "Live-Edit Character Sheet", "Proper support for mobile and desktop", "Unobfuscated websocket structure", "Added Proficiencies Feature", "Updating sheet from deltas"]
    const piBoardText = ["PiBoard", "Javascript, Raspberry Pi, Python", "PiBoard represents a set of mini-projects in which I linked my custom tray-shortcut app to my website server to remotely control a decorative LED grid panel I made with a Raspberry Pi. \n\nI wanted to be able to upload images, slideshows, and videos from my computer, so this project took me forever to complete. I learned a lot about networking and systems solutions, as well as the connection between hardware and software. \n\nLinked below is the Github page with a little more information and all the server-based code.", "Homemade IOT", "Desktop Tray Controlloer", "Freddy Fazbear in LEDs", "First Tray Mockup", "Video upload feature"]
    const charSheetText = ["Character Sheet", "Node.js, Express, Javascript, SQL", "Character Sheet is a webapp I created to help me and my friends keep track of our Dungeons and Dragons campaign. V2 IS NOW OUT. \n\nD&D is known for complicated rulesets, and limited space on physical character sheets didn't help. I solved this with a lightweight webapp written in vanilla JS that syncs to a database on edit. \n\nAlmost every element has a modal with more information, so have fun messing around! \n\nLinked below is the webapp, but be warned: Version 1 is poorly optimized for desktops and has sync issues. Check the newer Character Sheet project for more details.", "Remote Character Editor", "Sample D&D Character", "Scraping HTML for data", "Automatic damage modifiers", "Simple database integration"];
    const roscriptText = ["Roscript", "C, RSE", "Roscript is an esoteric programming language I created written only in special characters. \n\nI started Roscript as part of a C rewrite and expansion of another esoteric language, Brainfsck. Completed over the course of a couple days, this language interprets one of 14 instructions from an instruction tape to modify and perform operations on a Turing tape. \n\nAlong with defining Roscript's syntax, I also created a RSE debug interpreter and an RSE compiler that converts your Roscript files into executable machine code. \n\nRefer to the documentation at the Github link below for more.", "Custom Esoteric Language", "Example of roscript program", "Instruction documentation", "Excerpt of C interpreter", "Compilation documentation"];
    const pokeText = ["PokéRate", "Node.js, Express, Javascript, SQL", "Pokérate is a micro-project I created to settle an argument by using a data-driven approach to find the objectively coolest-looking Pokémon.\n\n Written in JS and hosted on an open server port, this project uses Node, Express, and SQL to provide images and store leaderboard information. \n\nThrough a weighted recommendation algorithm, PokéRate gives the user a comparative choice between two Pokémon, using their weights to dynamically adjust their ranking using another algorithm based off of the Chess Elo rating system.\n\nLinked below is the webapp, if you would like to try it out yourself.", "Dynamic Pokémon Rating System", "Presented with two choices", "Some frontend Javascript Fetching", "Cropped view of leaderboard", "Custom recommendation algorithm"];
    const calcText = ["LoveCalc", "Node.js, Express, Javascript", "LoveCalc is a small, fun program I wrote to get some experience deploying webapps with node/express before attempting a bigger project. \n\nHosted on the same server as my website, this simple program takes in two names and returns a 'compatibility score' through a custom REST API I wrote. After writing the backend, I took some extra time to model the frontend off of gitchy Web 1.0 kitty-core personal websites of the 90s, complete with some ancient animations.\n\nLinked below is the webapp itself, if for some reason you would like to try it out.", "'Compatibility' Calculator", "Backend Setup", "Website", "Look at the API", "Ross and Rachel"];
    const rendelText = ["Rendel", "Java, JavaSwing", "Rendel is my custom ray-tracing renderer built off the Blinn-Phong reflection model. \n\nBuilt in a week between midterms, when my classes felt a little more lax, it doesn't have all of the features I hoped it would. However, it does currently boasts occlusion, ray-traced reflections, a wide range of materials, a small animation library, and as many lights or shapes you want in a user-friendly input file.\n\n Linked below is the github page for Rendel, where I go into detail on the process, as well as other fun tidbits, like why I named it Rendel. :)", "Real-time Raytracing Renderer", "Initial ray-traced spheres", "Ambient lighting", "Diffuse lighting", "Specular lighting", "Final composite Blinn-Phong render"];
    const streetText = ["Intersecty Street", "Unity, C#, Blender", "Intersecty Street is a Frogger-like clone of the game 'Crossy Road' I developed with a friend over winter break. \n\nWe utilized a modified version of the Agile methodology along with a Kanban board to produce a polished game in Unity under a strict time limit. Though we had made games in the past, none were as polished nor technically accurate as this one. \n\n Linked below is the itch.io page so you can try out Intersecty Street for yourself!", "Voxelized Frogger-like", "In-game look", "Untextured models", "Loaded in Unity", "We're winning!"];
    const workText = ["A Day at Work", "Unity, C#, Photoshop", "A Day at Work is a time-loop visual novel game that I created for the LDJAM47 Hackathon with a group of friends. \n\nWe were very new to creating games at the time, and taking on the challenge of making a time-travel game in three days was certainly a challenge. In this game, you play as a character who is stuck in a time loop of their boss's design, and have to break out using knowledge gained from previous loops.\n\n This is one of my more visually polished hackathon projects by far, but also the most mechanically unpolished. Linked below is the Itch.io page for the game, but be warned.", "Time Loop Visual Novel", "Peek at Unity Editor", "Themed text-boxes", "Branching dialogue system", "Congratulations!"];
    const duckText = ["Duckinator", "Java, JavaFx, FTCLib", "Duckinator is a GUI-based mobile-robot path planning application I made to make programming FTC Robots easier. \n\nThe program lets users input points along a virtual robotics field. A number of helper functions work to generate an indented copy/paste-able text output for mobile FTC robot control that can be quickly iterated upon by the user. Used by >70 teams, the goal of this program isn't to replace programmers, but to give them a place to start. \n\n Linked below is the github page for this project.", "Mobile Robot Path-Planning GUI", "Pixel-to-inches conversion code", "Interface before interaction", "Test path with generated code", "Paste-able test path code compliant with FTCLib"];
    const nonoText =["Nonograms", "Java, JavaFx", "Nonograms is a tile-based puzzler that I created for my COMP301 Foundations of Programming class. \n\nUsing a MVC (Model-View-Controller) architectural pattern, I built a JavaFx application that uses mouse clicks as inputs on a virtual grid as you solve the puzzle given numerical clues on the top and sides of the grid. \n\nOnce the number of contiguous selected tiles in each row and column matches the given clues, you reach a win state. You can also create new boards easily through the intuitive clue-input file.", "Point-and-Click Puzzle Game", "One of three solving algorithms", "Untouched GUI", "Easy-to-use board creator", "Solved puzzle"];
    const boxText =["Autonomous Bartender", "Python, Blender, Raspberry Pi", "The Autonomous Bartender is a startup project I worked on during the start of the pandemic. \n\nAs a founding member as well as the only CS major, my role in the project was both in project management and programming. Over the course of project we developed the idea through dozens of prototypes before landing at a model near the final render.\n\n Since Spring 2022 this project has been on hiatus due to personal, extraneous circumstances.", "Autonomous Robotic Bartender", "Final model pre-render", "Early wooden turntable prototype", "Hanging-bottle prototype", "First working plastic display prototype", "Final model render"];


    return (
        <div className='whole'>
            <p className='titleProjects' id='projects'> 
                <b>Projects</b>
            </p>
            <br></br>
            <br></br>
            <div className='grid'>
                <div><GridComp picture={charImages} text = {charText} github = "https://rohanakki.com:4144/" actualGithub="Link"/></div>
                <div><GridComp picture={piBoardImages} text = {piBoardText} github = "https://github.com/yup-its-rowan/ledGridPage" actualGithub="Github"/></div>   
                <div><GridComp picture={charSheetImages} text = {charSheetText} github = "https://rohanakki.com:4143/" actualGithub = "Link"/></div>
                <div><GridComp picture={roscriptImages} text = {roscriptText} github = "https://github.com/yup-its-rowan/roscript" actualGithub = "Github"/></div>
                <div><GridComp picture={pokeImages} text = {pokeText} github = "https://rohanakki.com:1510/" actualGithub = "Link"/></div>
                <div><GridComp picture={calcImages} text = {calcText} github = "https://rohanakki.com:14324/" actualGithub = "Link"/></div>
                <div><GridComp picture={rendelImages} text = {rendelText} github = "https://github.com/yup-its-rowan/rayTracer/" actualGithub = "Github"/></div>
                <div><GridComp picture={streetImages} text = {streetText} github = "https://macarowan.itch.io/intersectystreet" actualGithub = "Itch.io"/></div>
                <div><GridComp picture={boxImages} text = {boxText}/></div>
                <div><GridComp picture={workImages} text = {workText} github = "https://macarowan.itch.io/a-day-at-work" actualGithub = "Itch.io"/></div>
                <div><GridComp picture={duckImages} text = {duckText} github = "https://github.com/yup-its-rowan/duckinator-3000" actualGithub = "Github"/></div>
                <div><GridComp picture={nonoImages} text = {nonoText}/></div>
            </div>
        </div> 
    );
}
