import React from 'react';
import "@reach/dialog/styles.css"; //need this for the dialog box to work properly
import { Dialog} from "@reach/dialog";
import './GridComp.css';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import closeIcon from './close.png';

export default function GridComp(props){
    //This is the modal for now
    const [showDialog, setShowDialog] = React.useState(false);
    const open = () => {
      setShowDialog(true);
      if (!document.getElementById('element').classList.contains("hide")){
        document.getElementById("element").classList.add("hide");
      }
    }
    const close = () => {
      setShowDialog(false);
      if (document.getElementById('element').classList.contains("hide")){
        document.getElementById("element").classList.remove("hide");
      }
    }
    const images = [];
    for (let i = 0; i < props.picture.length; i++) {
      images.push({ //the descriptions text is weird. first is tech, then gen description, then a lot of picture descriptions
        original: props.picture[i],
        thumbnail: props.picture[i],
        description: props.text[i+3],
    });
    }    
    
    return (
        <div className="modal" onClick={open} title={props.text[0]}>
            <br></br>
            <img className="image" src={props.picture[0]} alt="Main" onClick={open}></img>
            <p className='otherTechNearby'><b>{props.text[3]}</b></p>
            <p className='tech'><em>{props.text[1]}</em></p>
            <br></br>

            <Dialog isOpen={showDialog} onDismiss={close} className='dialog' aria-label='cheese'>
                <div className='content'>
                  <div className='closeButtonDiv'>
                    <img id="buttonTime" className='close-button' alt = "close button" onClick={close} src = {closeIcon}></img>
                  </div>
                  <br></br>
                  <div className='flexCarouselDiv'>
                    <div className='imageGalleryDiv'>
                      <ImageGallery items={images} showPlayButton={false}/>
                    </div>
                    <br></br>
                    <div className='textDivCarousel'>
                      <h1 className='titleTextH1'>{props.text[0]}</h1>
                      <p className='bigText'>{props.text[2]}</p>
                      <br></br>
                      { props.github ? <a href={props.github} target="_blank" rel="noreferrer" className='bigText linky'>{props.actualGithub}</a> : null}
                    </div>
                  </div>
                </div>
                
            </Dialog>
        </div>
    );
}