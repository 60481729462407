import React from "react";
import './About.css';
import picture from './betterPicture.png';

export default function About(){
    const text = "Some of my hobbies include writing, programming, speedrunning, and updating my ";
    const introText = "Welcome to my website! My name is Rohan Akki. I'm a Senior at the University of North Carolina at Chapel Hill, working towards a B.S. in both Computer Science and Business Administration."
    return (
        <div className="aboutWrapper">
            <ul className="aboutUl">
                <li>
                    <img className = "mePicture" src={picture} alt="Rohan Akki"></img>
                </li>
                <li className="aboutTotal">
                    <br></br>
                    <p className="aboutMe"> 
                        <b>About Me</b>
                    </p>
                    <div className="innerAbout" >
                        <br></br>
                        <p className="aboutText">{introText}</p>
                        <br></br>
                        <p className="aboutText">
                            {text}
                            <a href="https://rohanakki.com/RohanAkki_WebsiteResume.pdf" target="_blank" rel="noreferrer">resume</a>
                            .
                            I'm always making new things, so make sure to check out my <a href="#projects">projects</a> below!
                        </p>
                        
                    </div>

                </li> 
            </ul>
            <div className="about2">
                <p className="aboutMe"> 
                    <b>About Me</b>
                </p>
                <br></br>
                <img className = "mePicture" src={picture} alt="Rohan Akki"></img>
                <br></br>
                <div className="innerAbout">
                        <br></br>
                        <p className="aboutText">{introText}</p>
                        <br></br>
                        <p className="aboutText">
                            {text}
                            <a href="https://rohanakki.com/RohanAkki_WebsiteResume.pdf" target="_blank" rel="noreferrer">resume</a>
                            .
                            I'm always making new things, so make sure to check out my <a href="#projects">projects</a> below!
                        </p>
                        
                    </div>
            </div>
        </div>
    );
};
