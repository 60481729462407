import './App.css';
import {React} from 'react';
import Header from './Header/Header';
import FadeUpAbout from './FadeUpWrapper/FadeUpAbout';
import FadeUpGrid from './FadeUpWrapper/FadeUpGrid';
import Contact from './Contact/Contact';
import FadeUpHr from './FadeUpWrapper/FadeUpHr';
import FadeUpExperience from './FadeUpWrapper/FadeUpExperience';
import { useMousePosition } from './useMousePosition';

export default function App() {
  const position = useMousePosition();
  return (
    <div className="App" >
      <Header/>
      <div className="body">
        <div className='blankSpaceTop'></div>
        <p className='rohanAkki'>
        Rohan Akki
        </p>
        <p>Programmer | Designer | Writer | Engineer</p>
        <div className='blankSpaceBottom'></div>
        <div className='blankSpaceBottom2'  id="aboutMe"></div>
        <br></br>
        <FadeUpHr pos='170'/>
        <FadeUpAbout/>
        <FadeUpHr pos='700'/>
        <FadeUpExperience/>
        <FadeUpHr pos='1300'/>
        <FadeUpGrid/>
        <hr></hr>
        <br></br>
        <Contact/>
      </div>
      <div className="pointerGlow" style={{background: "radial-gradient(700px at " + position.x + "px " + position.y + "px, rgba(67, 80, 148, 0.15), transparent 80%)"}}></div>
    </div>
    
  );
}