import React, { useEffect, useState } from "react";
import './FadeUpWrapper.css';
import About from "../About/About";

export default function FadeUpWrapper() {

    const hasScrolled = useScroll();

    return (
        <div className={`all ${ hasScrolled === "true" ? "done" : "goUp"}`}>
            <About/>
        </div>
    );
};

function useScroll(){
    const[hasScrolled, setScrollVal] = useState(null);
    useEffect(() => {
        const updateScrollPosition = () => {
            const scrollY = window.pageYOffset;
            //200 is the pixel count but im unsure if thats a good idea
            if (scrollY > 250){
                setScrollVal("true");
            }
            //console.log(window.pageYOffset);
        };
        window.addEventListener("scroll", updateScrollPosition);
        return () => {
            window.removeEventListener("scroll", updateScrollPosition); // clean up
        }
    }, [hasScrolled]);
    return hasScrolled;
}