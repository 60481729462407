import React from 'react';
import './Header.css';
import { useState, useEffect } from 'react';

export default function Header() {
  const scrollDirection = useScrollDirection();
  
  return (
    <div id ="element" className={`disappear ${ scrollDirection === "down" ? "hide" : "show"}`}>
      <div className="links">
        <div className='outerMid'><a className='mid' href="#aboutMe">About</a></div>
        <div className='outerMid'><a className='mid'  href="#experience">Experience</a></div>
        <div className='outerMid'><a className='mid'  href="#projects">Projects</a></div>
        <div className='outerMid'><a className='mid'  href="#contact">Contact</a></div>
      </div>
    </div>
  );
};

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
      let lastScrollY = window.pageYOffset;
      // function to run on scroll
      const updateScrollDirection = () => {
          const scrollY = window.pageYOffset;
          const direction = scrollY > lastScrollY ? "down" : "up";
          if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
            setScrollDirection(direction);
          }
          lastScrollY = scrollY > 0 ? scrollY : 0;

          if (window.scrollY >= 100 || window.pageYOffset >= 100) {
            if (!document.getElementById('element').classList.contains("inverted")){
              document.getElementsByClassName("disappear")[0].classList.add("inverted");
            }
            
          } else {
            if (document.getElementById('element').classList.contains("inverted")){
              document.getElementsByClassName("disappear")[0].classList.remove("inverted");
            }
          }
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
          window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
  }, [scrollDirection]); // run when scroll direction changes
  return scrollDirection;
};
