import React from "react";
import './Contact.css';
import googleDoc from './googledocs.png';
import linkedin from './linkedin.svg';
import github from './github.svg';
import email from './gmail.svg';

export default function Contact() {
    return (
        <div className="contactGrid">
            <div>
            <p className="contactMe" id="contact"><b>Contact Me!</b></p>
            </div>
            <div> 
                <a title="Email" href="mailto:roha.akki@gmail.com" target="_blank" rel="noreferrer"><img className="logo" src={email} alt="Gmail"></img></a>
                <a title="LinkedIn" href="https://www.linkedin.com/in/rohan-akki/" target="_blank" rel="noreferrer"><img className="logo" src={linkedin} alt="LinkedIn"></img></a>
                <a title="GitHub" href="https://github.com/yup-its-rowan" target="_blank" rel="noreferrer"><img className="logo" src={github} alt="Github"></img></a>
                <a title="Resume" href="https://rohanakki.com/RohanAkki_WebsiteResume.pdf" target="_blank" rel="noreferrer"><img className="logo" src={googleDoc} alt="Resume"></img></a>
            </div>
        </div>
    );
}