import React, { useEffect, useState } from "react";
import './FadeUpWrapper2.css';

export default function FadeUpWrapper(props) {

    const hasScrolled = useScroll(props.pos);

    return (
        
        <div className={`all ${ hasScrolled === "true" ? "done2" : "goUp2"}`}>
            <hr></hr>
        </div>
    );
};

function useScroll(pos){
    const[hasScrolled, setScrollVal] = useState(null);
    useEffect(() => {
        const updateScrollPosition = () => {
            const scrollY = window.pageYOffset;
            //200 is the pixel count but im unsure if thats a good idea
            if (scrollY > pos){
                setScrollVal("true");
            }
        };
        window.addEventListener("scroll", updateScrollPosition);
        return () => {
            window.removeEventListener("scroll", updateScrollPosition); // clean up
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasScrolled]);
    return hasScrolled;
}