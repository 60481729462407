export const Rendel = require("./Rendel/rendel.png");
export const Rendel1 = require("./Rendel/rendel1.png");
export const Rendel2 = require("./Rendel/rendel2.png");
export const Rendel3 = require("./Rendel/rendel3.png");
export const Rendel4 = require("./Rendel/rendel4.png");
export const Rendel5 = require("./Rendel/rendel5.png");

export const Street = require("./IntersectyStreet/Street.png");
export const Street1 = require("./IntersectyStreet/Street1.png");
export const Street2 = require("./IntersectyStreet/Street2.png");
export const Street3 = require("./IntersectyStreet/Street3.png");
export const Street4 = require("./IntersectyStreet/Street4.png");

export const Work = require("./Work/Work.png");
export const Work1 = require("./Work/Work1.png");
export const Work2 = require("./Work/Work2.png");
export const Work3 = require("./Work/Work3.png");
export const Work4 = require("./Work/Work4.png");

export const Duck = require("./Duckinator/Duck.png");
export const Duck1 = require("./Duckinator/Duck1.png");
export const Duck2 = require("./Duckinator/Duck2.png");
export const Duck3 = require("./Duckinator/Duck3.png");
export const Duck4 = require("./Duckinator/Duck4.png");

export const Nono = require("./Nono/Nono.png");
export const Nono1 = require("./Nono/Nono1.png");
export const Nono2 = require("./Nono/Nono2.png");
export const Nono3 = require("./Nono/Nono3.png");
export const Nono4 = require("./Nono/Nono4.png");

export const Box = require("./Box/Box.png");
export const Box1 = require("./Box/Box1.png");
export const Box2 = require("./Box/Box2.png");
export const Box3 = require("./Box/Box3.png");
export const Box4 = require("./Box/Box4.png");
export const Box5 = require("./Box/Box5.png");

export const Calc = require("./LoveCalc/Calc1.png");
export const Calc1 = require("./LoveCalc/Calc2.png");
export const Calc2 = require("./LoveCalc/Calc3.png");
export const Calc3 = require("./LoveCalc/Calc4.png");
export const Calc4 = require("./LoveCalc/Calc5.png");

export const Poke = require("./PokeRate/Poke1.png");
export const Poke1 = require("./PokeRate/Poke2.png");
export const Poke2 = require("./PokeRate/Poke3.png");
export const Poke3 = require("./PokeRate/Poke4.png");
export const Poke4 = require("./PokeRate/Poke5.png");

export const Roscript = require("./Roscript/Roscript1.png");
export const Roscript1 = require("./Roscript/Roscript2.png");
export const Roscript2 = require("./Roscript/Roscript3.png");
export const Roscript3 = require("./Roscript/Roscript4.png");
export const Roscript4 = require("./Roscript/Roscript5.png");

export const CharSheet = require("./CharSheet/Char1.png");
export const CharSheet1 = require("./CharSheet/Char2.png");
export const CharSheet2 = require("./CharSheet/Char3.png");
export const CharSheet3 = require("./CharSheet/Char4.png");
export const CharSheet4 = require("./CharSheet/Char5.png");

export const Char = require("./CharSheet2/Chars1.png");
export const Char1 = require("./CharSheet2/Chars2.png");
export const Char2 = require("./CharSheet2/Chars3.png");
export const Char3 = require("./CharSheet2/Chars4.png");
export const Char4 = require("./CharSheet2/Chars5.png");

export const PiBoard = require("./PiBoard/Board1.png");
export const PiBoard1 = require("./PiBoard/Board2.png");
export const PiBoard2 = require("./PiBoard/Board3.png");
export const PiBoard3 = require("./PiBoard/Board4.png");
export const PiBoard4 = require("./PiBoard/Board5.png");
