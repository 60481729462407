import React, { useEffect, useState } from "react";
import './FadeUpWrapper.css';
import Grid from '../Grid/Grid';

export default function FadeUpWrapper() {

    const hasScrolled = useScroll();

    return (
        <div className={`all ${ hasScrolled === "true" ? "done" : "goUp"}`}>
            <Grid/>
        </div>
    );
};

function useScroll(){
    const[hasScrolled, setScrollVal] = useState(null);
    useEffect(() => {
        const updateScrollPosition = () => {
            const scrollY = window.pageYOffset;
            //200 is the pixel count but im unsure if thats a good idea
            if (scrollY > 1350){
                setScrollVal("true");
            }
        };
        window.addEventListener("scroll", updateScrollPosition);
        return () => {
            window.removeEventListener("scroll", updateScrollPosition); // clean up
        }
    }, [hasScrolled]);
    return hasScrolled;
}