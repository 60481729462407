import React, { useEffect } from "react";
import "./Experience.css";

const text = [
    ["Surescripts LLC", "June 1st 2022 - Aug 5th 2022", "Worked in Agile teams to create new set of internal dev tools along with fellow interns in Minneapolis, Cary, and Arlington", "Created and deployed single page application in Angular with an ASP.NET backend through Jenkins", "Automated parsing HL7v2 messages into a customizable, human-readable format" ],
    ["UNC - Chapel Hill", "August 6th 2020 - May 5th 2024", "Currently working through B.S. in Computer Science and a B.S. in Business Administration at the University of North Carolina at Chapel Hill", "Relevant courses include Modern Web Programming, Operating Systems, Intro into Machine Learning, Algorithm Analysis, etc.", "Currently also in the Kenan-Flagler Business School with a GPA of 3.72 and an anticipated graduation date in Spring 2024"],
    ["Volvo Group", "May 15th 2023 - Decenver 15th 2023", "Developed reporting tools to facilitate application migration from proprietary backend across Microsoft Azure DevOps", "Presented and documented process landscape and process transformation maps in PowerBI to relevant stakeholders", "Aligned scraped information from cross-functional Volvo Group teams intuitively through management products"]
];
const idList = ['surescripts', 'chapel', 'volvo'];

export default function Experience() {

    useEffect(() => {
        changeText(2);
    }, []);

    return(
        <div className="experience" id="experience">
            <p className="experienceTitle"><b>Experience</b></p>
            <div className="experienceFlexbox">
                <div className="experienceOptions">
                    <div className="wrapperDivStuff">
                        <p className="optionsOuter chosen" id="volvo" onClick={() => changeText(2)}>Volvo Group</p>
                        <p className="optionsOuter" id="surescripts" onClick={() => changeText(0)}>Surescripts</p>
                        <p className="optionsOuter" id="chapel" onClick={() => changeText(1)}>UNC - CH</p>
                    </div>
                </div>
                <div className="innerText">
                    <p id="ExperienceTitle" className="innerTitle"></p>
                    <p id="ExperienceDates" className="innerDates"></p>
                    <ul>
                        <li id="ExperienceDescription1" className="innerDescription"></li>
                        <li id="ExperienceDescription2" className="innerDescription"></li>
                        <li id="ExperienceDescription3" className="innerDescription"></li>
                    </ul>           
                </div>
            </div>
        </div>
    );
}

function changeText(id) {
    if (!document.getElementById(idList[id]).classList.contains("chosen")) {
        document.getElementById(idList[id]).classList.add('chosen');
    }
    for (var i = 0; i < idList.length; i++) {
        if (i !== id) {
            if (document.getElementById(idList[i]).classList.contains("chosen")) {
                document.getElementById(idList[i]).classList.remove('chosen');
            }
        }
    }
    document.getElementById('ExperienceTitle').innerHTML=text[id][0];
    document.getElementById('ExperienceDates').innerHTML=text[id][1];
    document.getElementById('ExperienceDescription1').innerHTML=text[id][2];
    document.getElementById('ExperienceDescription2').innerHTML=text[id][3];
    document.getElementById('ExperienceDescription3').innerHTML=text[id][4];
}
/*
<div className="container">

                
                <div className="rightbox">
                     <div className="rb-container">
                        <ul className="rb">
                            <li className="rb-item" ng-repeat="itembx">
                                <div className="timestamp">
                                    1st June 2022 - 5th August 2022
                                </div>
                                <div className="item-title">Interned at Surescripts LLC</div>
                            </li>

                            <li className="rb-item" ng-repeat="itembx">
                                <div className="timestamp">
                                    10th Aug 2020 - 5th May 2024
                                </div>
                                <div className="item-title">Started B.S. in Computer Science at Chapel Hill</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>



*/